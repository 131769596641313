import {
  ApplicationRef,
  ChangeDetectionStrategy,
  Component,
  NgZone,
  OnDestroy,
  OnInit,
  Signal,
  afterNextRender,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationStart, Router, RouterOutlet } from '@angular/router';
import { faCheckSquare, faSquare } from '@fortawesome/free-solid-svg-icons';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { TranslateService } from '@ngx-translate/core';
import { StateService } from '@common/data-access/service/state/state.service';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { Subscription } from 'rxjs';
import { ILang } from '@common/interface/common.interface';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, NzSpinModule],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  loading!: Signal<boolean>;
  subs: Subscription = new Subscription();

  constructor(
    public library: FaIconLibrary,
    private _translate: TranslateService,
    private _stateService: StateService,
    private _router: Router,
    private _zone: NgZone,
    private _applicationRef: ApplicationRef
  ) {
    library.addIcons(faSquare, faCheckSquare);
    afterNextRender(() => {
      this.setDefaultLanguage();
    });
  }

  ngOnInit(): void {
    this._stateService.setLoadingPage(true);

    this.subs.add(
      this._router.events.subscribe((event) => {
        // To handle issue about back button in browser.
        if (
          event instanceof NavigationStart &&
          event?.navigationTrigger === 'popstate'
        ) {
          this._zone.run(() => {
            this._applicationRef.tick();
          });
        }
      })
    );

    this.loading = this._stateService.getLoadingPage();
    this.languageListener();
  }

  ngOnDestroy(): void {
    this.subs?.unsubscribe();
  }

  setDefaultLanguage(): void {
    if (this._stateService.getLocalLanguage() === null) {
      this.translateChangeHandler(this._translate.getBrowserLang() as string);
    } else {
      this.translateChangeHandler(this._stateService.getLocalLanguage());
    }
  }

  translateChangeHandler(lang: string): void {
    let applyLang = lang;

    switch (lang.toLocaleLowerCase()) {
      case 'en':
        this._translate.use(ILang.EN_US);
        break;

      case 'id':
        this._translate.use(ILang.ID_ID);
        break;

      // case 'kh':
      //   this._translate.use('km_KH');
      //   break;

      case 'zh':
      case 'zh_tw':
        // Make sure stateService will save 'zh_TW' instead of 'zh', because main.component will use LANG.ZH_TW to display language name
        applyLang = 'zh_TW';
        this._translate.use(ILang.ZH_TW);
        break;

      case 'zh_cn':
        this._translate.use(ILang.ZH_CN);
        break;

      default:
        break;
    }

    this._stateService.setLanguage(applyLang.toUpperCase());
  }

  languageListener(): void {
    this.subs.add(
      this._translate.onLangChange.subscribe(() =>
        this._stateService.setLoadingPage(false)
      )
    );
  }
}
